import React from "react";
import {
  PullUpBarAffiliateLink,
  RingsAffiliateLink,
  ParallettesAffiliateLink,
  BandsAffiliateLink,
  DipStationAffiliateLink,
  ChalkAffiliateLink,
  AbWheelAffiliateLink
} from "../../components/affiliates";
import { Dictionary } from "../../models";

interface Exercise {
  name: string;
  path?: string;
}

interface Equipment {
  title: string;
  description: React.ReactElement;
  exercises: Exercise[];
  link: React.ReactElement;
  imgKey: string;
}

const exerciseMap: Dictionary<Exercise> = {
  pullUp: { name: "Pull Up", path: "/exercises/pull-up/" },
  backLever: { name: "Back Lever" },
  frontLever: { name: "Front Lever" },
  toesToBar: { name: "Toes to Bar" },
  hangingLsit: { name: "Hanging L-Sits" },
  plank: { name: "Plank", path: "/exercises/plank/" },
  pushUp: { name: "Push Ups", path: "/exercises/push-up/" },
  ringDips: { name: "Ring Dips" },
  parallelBarDips: {
    name: "Parallel Bar Dips",
    path: "/exercises/parallel-bar-dip"
  },
  straightBarDips: {
    name: "Straight Bar Dips"
  },
  rows: { name: "Rows", path: "/exercises/inverted-row/" },
  handstand: { name: "Handstand", path: "/exercises/handstand/" },
  muscleUp: { name: "Muscle Up", path: "/exercises/muscle-up/" },
  lsit: { name: "L-Sit", path: "/exercises/l-sit" },
  planche: { name: "Planche" },
  squats: { name: "Squats", path: "/exercises/bodyweight-squat" },
  abWheelRollout: { name: "Ab Wheel Rollout" }
};

const equipmentData: Equipment[] = [
  {
    title: "Pull Up Bar",
    description: (
      <p>
        The pull up bar is essential for all home gyms. For anyone who wants to
        improve their pulling strength, the pull up bar is a must-have. There
        are many different types of pull up bars, we find the ones that work
        best for us are those that fit in a door frame. You can also purchase a
        wall-mounted pull up bar, but they tend to be more expensive and require
        time to set up.
      </p>
    ),
    exercises: [
      exerciseMap.pullUp,
      exerciseMap.backLever,
      exerciseMap.frontLever,
      exerciseMap.toesToBar,
      exerciseMap.hangingLsit
    ],
    link: <PullUpBarAffiliateLink>Buy a pull up bar</PullUpBarAffiliateLink>,
    imgKey: "pullUpBar"
  },
  {
    title: "Gymnastic Rings",
    description: (
      <>
        <p>
          The only other piece of equipment we regard as essential. The
          gymnastic rings are extremely versatile, and their uses almost
          limitless. It's possible to gain a lot of strength and size using
          nothing more than the gymnastic rings. We recommend the wooden rings
          as they're easier to grip. Gymanastic rings easy to set up, can have
          their heights adjusted, and are super lightweight.
        </p>
        <p>
          Even floor exercise like the Plank benefit from being performed using
          the rings.
        </p>
      </>
    ),
    exercises: [
      exerciseMap.pullUp,
      exerciseMap.plank,
      exerciseMap.handstand,
      exerciseMap.rows,
      exerciseMap.muscleUp,
      exerciseMap.frontLever,
      exerciseMap.backLever
    ],
    link: <RingsAffiliateLink>Buy a pair of rings</RingsAffiliateLink>,
    imgKey: "rings"
  },
  {
    title: "Parallettes",
    description: (
      <p>
        Parallettes have many uses. They're great pieces of equipment for those
        with wrist issues, who experience discomfort when performing handstands
        or push ups. Parallettes are also raised off the ground, meaning you can
        increase the range of motion of exercises like the Push Up, or Handstand
        Push Up. Parallettes also enable you to practice more challenging core
        movements, like the L-Sit.
      </p>
    ),
    exercises: [
      exerciseMap.lsit,
      exerciseMap.pushUp,
      exerciseMap.handstand,
      exerciseMap.planche
    ],
    link: (
      <ParallettesAffiliateLink>
        Buy a pair parallettes
      </ParallettesAffiliateLink>
    ),
    imgKey: "parallettes"
  },
  {
    title: "Resistance Bands",
    description: (
      <p>
        Like the gymnastic rings, resistance bands are lightweight and
        versatile. You can use them to bridge the gaps between difficult
        progressions, as they can reduce the resistance needed to perform an
        exercise. For instance, you can use them to support your bodyweight
        during a Pull Up, which is great for those working toward their first
        Pull Up. On the flip-side, you can use resistance bands to make
        exercises more challenging. You can make your squats and push ups more
        challenging.{" "}
        <a
          href="https://www.fix.com/blog/work-out-with-resistance-bands/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Here's a handy article
        </a>{" "}
        to help you learn more about resistance bands.
      </p>
    ),
    exercises: [
      exerciseMap.pushUp,
      exerciseMap.squats,
      exerciseMap.pullUp,
      exerciseMap.muscleUp,
      exerciseMap.parallelBarDips,
      exerciseMap.rows,
      exerciseMap.plank,
      exerciseMap.planche,
      exerciseMap.frontLever,
      exerciseMap.backLever
    ],
    link: <BandsAffiliateLink>Buy some resistance bands</BandsAffiliateLink>,
    imgKey: "resistanceBands"
  },
  {
    title: "Dip Bars",
    description: (
      <p>
        The dip bars are excellent pieces of equipment for those with enough
        space for them. Having dip bars ensures you can practice your Parallel
        Dips and Straight Bar Dips any time. They double up as an alternative
        for the parallettes, meaning any exercise you can do on the parallettes,
        you can do with the dip bars. Aside from the rings, the dip bars are the
        only pieces of equipment on this list needed to perform a Row. The Row
        is a required exercise in the Recommended Routine.
      </p>
    ),
    exercises: [
      exerciseMap.parallelBarDips,
      exerciseMap.muscleUp,
      exerciseMap.lsit,
      exerciseMap.rows,
      exerciseMap.handstand,
      exerciseMap.straightBarDips,
      exerciseMap.planche
    ],
    link: (
      <DipStationAffiliateLink>Buy a pair of dip bars</DipStationAffiliateLink>
    ),
    imgKey: "dipStation"
  },
  {
    title: "Chalk",
    description: (
      <p>
        A little messy, but a bag of chalk can do wonders for your grip
        strength. You can use chalk when training for more challenging,
        grip-intensive exercises, like the Ring Muscle Up, or anything involving
        a pull up bar. Chalk is super lightweight, which means you can carry it
        around anywhere. It's also very popular in sports like bouldering.
      </p>
    ),
    exercises: [],
    link: <ChalkAffiliateLink>Buy some chalk</ChalkAffiliateLink>,
    imgKey: "chalk"
  },
  {
    title: "Ab Wheel",
    description: (
      <p>
        The ab wheel is very small, and very lightweight. While the Ab Wheel
        isn't as versatile as some of the other equipment on this list, you can
        use it to perform some very difficult core exercises, like the
        Straight-Legged Rollout. You can also adjust other exercises like the
        Plank to for an added challenge.{" "}
        <a
          href="https://alkavadlo.com/body-weight-exercises/the-top-five-ab-wheel-exercises/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Al Kavadlo
        </a>
        recommends his top 5 ab wheel exercises in this article.
      </p>
    ),
    exercises: [exerciseMap.plank, exerciseMap.abWheelRollout],
    link: <AbWheelAffiliateLink>Buy an ab wheel</AbWheelAffiliateLink>,
    imgKey: "abWheel"
  }
];

export default equipmentData;

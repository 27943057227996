import React from "react";
import equipmentData from "../data/equipment";
import TextPageLayout from "../components/layouts/TextPageLayout";
import SEO from "../components/SEO";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "../styles/ExercisePage.css";

const query = graphql`
  query {
    abWheel: file(relativePath: { eq: "equipment/ab-wheel.jpeg" }) {
      ...squareImage
    }
    chalk: file(relativePath: { eq: "equipment/chalk.jpg" }) {
      ...squareImage
    }
    dipStation: file(relativePath: { eq: "equipment/dip-station.jpeg" }) {
      ...squareImage
    }
    parallettes: file(relativePath: { eq: "equipment/parallettes.jpeg" }) {
      ...squareImage
    }
    pullUpBar: file(relativePath: { eq: "equipment/pullup.jpg" }) {
      ...squareImage
    }
    resistanceBands: file(
      relativePath: { eq: "equipment/resistance-bands.jpg" }
    ) {
      ...squareImage
    }
    rings: file(relativePath: { eq: "equipment/rings.jpg" }) {
      ...squareImage
    }
  }
`;

function EquipmentPage() {
  const imgData = useStaticQuery(query);

  return (
    <TextPageLayout title="Equipment">
      <SEO
        path="/equipment/"
        title="The Best Equipment to Start Training Calisthenics"
        description="Not sure if you have the right equipment to start calisthenics? Our equipment guide tells you everything you need, plus our top recommendations."
      />
      <h2>What equipment do I need for calisthenics?</h2>
      <p>
        With calisthenics, your body is the gym. Instead of paying for an
        expensive gym membership, you can get an effective and challenging
        workout from your home or a park. You can go further by buying your own
        calisthenics equipment. With a handful equipment you can keep making
        progress on your progressions. You will also be able to perform all the
        exercises in workouts like the Recommended Routine.
      </p>
      <p>
        We've recommended some of our favourite pieces of calisthenics equipment
        for you to build your home gym with. They're all beginner friendly.
        We've only listed equipment that we've used ourselves, and are happy to
        recommend. We've provided links for each one.
      </p>
      {equipmentData.map(equipment => {
        const { description, link, exercises, imgKey, title } = equipment;

        return (
          <div className="Equipment" key={imgKey}>
            <h3>{title}</h3>
            <div className="Equipment__content-container">
              <div>{description}</div>
              <div className="Equipment__image-container">
                <Img
                  fixed={imgData[imgKey].childImageSharp.fixed}
                  alt={title}
                />
              </div>
            </div>
            {exercises.length > 0 && (
              <div style={{ marginBottom: "24px" }}>
                <div>You can use the {title} for exercises like:</div>
                <ul style={{ listStyle: "circle", marginLeft: "16px" }}>
                  {exercises.map(({ path, name }) => {
                    return <Exercise key={name} path={path} name={name} />;
                  })}
                </ul>
              </div>
            )}
            <div style={{ fontSize: "24px" }}>{link}</div>
          </div>
        );
      })}
    </TextPageLayout>
  );
}

function Exercise(props: { name: string; path?: string }) {
  const { path, name } = props;

  if (!path) {
    return <li>{name}</li>;
  }

  return (
    <li>
      <Link to={path}>{name}</Link>
    </li>
  );
}

export default EquipmentPage;

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fixed(width: 160, height: 160) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;
